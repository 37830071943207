.title {
  width: fit-content;
  padding-bottom: 1px;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 5px;
}

.main_container {
  background-color: gainsboro;
  padding: 1rem;
  min-height: 10rem;
  margin-bottom: 5rem;
}

.user_container {
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: space-between;
  min-height: 5rem;
}
