.title {
  width: fit-content;
  padding-bottom: 1px;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 5px;
}

.main_container {
  background-color: gainsboro;
  padding: 1rem;
  min-height: 10rem;
  margin-bottom: 15rem;
}

.repository_container {
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: space-between;
  min-height: 5rem;
}

.repo_input_container {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.repo_update_button {
  padding-left: 1rem;
  padding-right: 1rem;
  min-width: 5rem;
}

.repo_input {
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: -webkit-fill-available;
}

.description {
  font-size: 12px;
}
